<template>
  <div class="user-not-login">
    <BaseButton
      id="header-mobile-login-btn"
      class="btn-login base-button--btn-transparent"
      @click="onClickAuthor(MODAL_TYPES.LOGIN, 'login')"
    >
      Đăng Nhập
    </BaseButton>
    <BaseButton
      id="header-mobile-register-btn"
      class="btn-register base-button--btn-primary"
      @click="onClickAuthor(MODAL_TYPES.LOGIN, 'register')"
    >
      Đăng Ký
    </BaseButton>
  </div>
</template>
<script setup>
import BaseButton from '~/components/common/base-button.vue'
import { MODAL_TYPES } from '~/config/constant'
const { onClickAuthor } = useHeader()
</script>
<style lang="scss" scoped src="public/assets/scss/components/mobile/header/user-not-login.scss" />
